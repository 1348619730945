import CONSTANTS from '../../constants';

export function promotions(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION) {
    const { promotion } = action;
    const promObjs = state ? state : {};
    if (promotion.errorMessage) {
      promObjs['currentManualPromoID'] = { errorMessage: promotion.errorMessage };
      return promObjs;
    }
    promObjs['currentManualPromoID'] = { id: promotion.id, code: promotion.code };
    const {
      PromotionDiscount: { discount_type, PromotionDiscountItems: promotionDiscountItems },
      PromotionOrderTypes
    } = promotion;
    const orderTypeIDs = PromotionOrderTypes.map((o) => o.order_type_id);
    if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.BASKET) {
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET].push(promotion);
    } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.ALL_ITEMS) {
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS].push(promotion);
    } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.SPECIFIC_ITEMS) {
      for (let i = 0; i < promotionDiscountItems.length; i++) {
        const item = promotionDiscountItems[i];
        if (item.item_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.ITEM_TYPES.ITEM) {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS][item.item] = {
            ...item,
            parent_id: promotion.id,
            max_discount: promotion.max_discount_amount,
            order_type_ids: orderTypeIDs,
            code: promotion.code,
            discount_amount: promotion.PromotionDiscount.discount_amount === 0 || promotion.PromotionDiscount.discount_amount === '' ? item.discount_amount : promotion.PromotionDiscount.discount_amount,
            discount_amount_type: promotion.PromotionDiscount.discount_amount_type,
            promotion_banner_img_url: promotion.promotion_banner_img_url,
            promotion_banner_status: promotion.promotion_banner_status,
            promotion_banner_sub_title: promotion.promotion_banner_sub_title,
            promotion_banner_title: promotion.promotion_banner_title,
            promotion_banner_type: promotion.promotion_banner_type
          };
        } else {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES][item.item] = {
            ...item,
            parent_id: promotion.id,
            max_discount: promotion.max_discount_amount,
            order_type_ids: orderTypeIDs,
            code: promotion.code,
            discount_amount: promotion.PromotionDiscount.discount_amount === 0 || promotion.PromotionDiscount.discount_amount === '' ? item.discount_amount : promotion.PromotionDiscount.discount_amount,
            discount_amount_type: promotion.PromotionDiscount.discount_amount_type,
            promotion_banner_img_url: promotion.promotion_banner_img_url,
            promotion_banner_status: promotion.promotion_banner_status,
            promotion_banner_sub_title: promotion.promotion_banner_sub_title,
            promotion_banner_title: promotion.promotion_banner_title,
            promotion_banner_type: promotion.promotion_banner_type
          };
        }
      }
    }
    return promObjs;
  }
  if (action.type === CONSTANTS.ACTIONS.PROMOTIONS.LIST) {
    const { promotions } = action;
    const promObjs = {};
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] = [];
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] = [];
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] = {};
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] = {};

    for (let i = 0; i < promotions.length; i++) {
      const promotion = promotions[i];
      if (promotion?.PromotionDiscount) {
        const {
          PromotionDiscount: { discount_type, PromotionDiscountItems: promotionDiscountItems },
          PromotionOrderTypes
        } = promotion;
        const orderTypeIDs = PromotionOrderTypes.map((o) => o.order_type_id);
        if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.BASKET) {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET].push(promotion);
        } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.ALL_ITEMS) {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS].push(promotion);
        } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.SPECIFIC_ITEMS) {
          for (let i = 0; i < promotionDiscountItems.length; i++) {
            const item = promotionDiscountItems[i];
            if (item.item_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.ITEM_TYPES.ITEM) {
              promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS][item.item] = {
                ...item,
                parent_id: promotion.id,
                max_discount: promotion.max_discount_amount,
                order_type_ids: orderTypeIDs,
                code: promotion.code,
                discount_amount: promotion.PromotionDiscount.discount_amount === 0 || promotion.PromotionDiscount.discount_amount === '' ? item.discount_amount : promotion.PromotionDiscount.discount_amount,
                discount_amount_type: promotion.PromotionDiscount.discount_amount_type,
                promotion_banner_img_url: promotion.promotion_banner_img_url,
                promotion_banner_status: promotion.promotion_banner_status,
                promotion_banner_sub_title: promotion.promotion_banner_sub_title,
                promotion_banner_title: promotion.promotion_banner_title,
                promotion_banner_type: promotion.promotion_banner_type
              };
            } else {
              promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES][item.item] = {
                ...item,
                parent_id: promotion.id,
                max_discount: promotion.max_discount_amount,
                order_type_ids: orderTypeIDs,
                code: promotion.code,
                discount_amount: promotion.PromotionDiscount.discount_amount === 0 || promotion.PromotionDiscount.discount_amount === '' ? item.discount_amount : promotion.PromotionDiscount.discount_amount,
                discount_amount_type: promotion.PromotionDiscount.discount_amount_type,
                promotion_banner_img_url: promotion.promotion_banner_img_url,
                promotion_banner_status: promotion.promotion_banner_status,
                promotion_banner_sub_title: promotion.promotion_banner_sub_title,
                promotion_banner_title: promotion.promotion_banner_title,
                promotion_banner_type: promotion.promotion_banner_type
              };
            }
          }
        }
      }
    }
    return promObjs;
  }
  return state;
}
