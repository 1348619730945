import CONSTANTS from '../../constants';

export function location(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.GET) {
    const { location } = action;
    // if (location && location.orderTypes) {
    //   for (let i = 0; i < location.orderTypes.length; i++) {
    //     location.orderTypes[i].pre_payment = false;
    //   }
    // }
    return location;
  }
  return state;
}
