import CONSTANTS from '../../constants';

export function childLocations(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.CHILDREN) return action.childLocations;

  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.OUTLETS) {
    const outlets = action.outlets;
    for (let i = 0; i < outlets.length; i++) {
      const { OutletStockStatuses } = outlets[i];
      const stockStatuses = {};
      const categoryStatuses = {};
      for (let j = 0; j < OutletStockStatuses.length; j++) {
        if (OutletStockStatuses[j].is_category) {
          categoryStatuses[OutletStockStatuses[j].menu_id] = OutletStockStatuses[j];
        } else {
          stockStatuses[OutletStockStatuses[j].menu_id] = OutletStockStatuses[j];
        }
      }
      outlets[i].stockStatuses = stockStatuses;
      outlets[i].categoryStatuses = categoryStatuses;
      delete outlets[i].OutletStockStatuses;
    }
    return outlets;
  }

  return state;
}
